import { AttackTab } from '../AttackTab';
import { DefenceTab } from '../DefenceTab';
import { InboxTab } from '../InboxTab';
import { InventoryTab } from '../InventoryTab';

export const tabs = ['Defence', 'Attack', 'Inbox', 'Inventory'];

export const tabContent = [
	<DefenceTab />,
	<AttackTab />,
	<InboxTab />,
	<InventoryTab />,
];
