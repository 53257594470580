import React from 'react';
import Modal from '../UI/Modal';
import { Purchases } from '../Purchases';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { hidePurchasesModalAction } from '../../store/actions/modal';

export const PurchasesModal: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isModalOpen = useAppSelector(
		(state) => state.modalReducer.PURCHASES_MODAL
	);

	return (
		<Modal
			isModalOpen={isModalOpen}
			dispatchHideModal={() => {
				dispatch(hidePurchasesModalAction());
			}}
		>
			<Purchases />
		</Modal>
	);
};
