import React from 'react';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';

import styles from './styles.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { logOutAction } from '../../store/actions/acoount';

export const HeaderComponent: React.FC = (): JSX.Element => {
	const isAuth = useAppSelector((state) => state.accountReducer.isAuthorized);
	const dispatch = useAppDispatch();

	return (
		<div className={styles.container}>
			<div className={styles.nickName}>{isAuth && <span>Player 1</span>}</div>

			<div>
				<div className={styles.options}>
					{isAuth ? (
						<>
							<Link to='/account' className={styles.cartLink}>
								My account
							</Link>
							<Link
								to='/'
								className={styles.cartLink}
								onClick={() => dispatch(logOutAction())}
							>
								Log out
							</Link>
						</>
					) : (
						<Link to='/login' className={styles.cartLink}>
							Log in or Create account
						</Link>
					)}

					<Link to='/cart' className={styles.cartLink}>
						<span>Cart</span>
						<span
							style={{
								marginLeft: '5px',
							}}
						>
							<ShoppingCartOutlined />
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};
