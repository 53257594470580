import axios from 'axios';
import { AuthService } from 'services/auth';
import {
	getLocalAccessToken,
	setLocalAccessToken,
	setLocalRefreshToken,
	removeLocalItem,
	Tokens,
} from './localStorage';

export const httpClient = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

httpClient.interceptors.request.use(
	(config) => {
		const accessToken = getLocalAccessToken();
		if (accessToken) {
			// @ts-ignore
			config.headers['Authorization'] = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

httpClient.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		if (err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;
				try {
					// const rs = await AuthService.refreshToken()
					const rs = {};

					// @ts-ignore
					if (rs && rs.error) {
						removeLocalItem(Tokens.ACCESS);
						removeLocalItem(Tokens.REFRESH);
						window.location.href = '/login';
						// @ts-ignore
						return Promise.reject(rs.error);
					}

					// @ts-ignore
					const { access_token, refresh_token } = rs;
					setLocalAccessToken(access_token);
					setLocalRefreshToken(refresh_token);

					httpClient.defaults.headers.common[
						'Authorization'
					] = `Bearer ${access_token}`;
					return httpClient(originalConfig);
				} catch (_error) {
					//  @ts-ignore
					if (_error.response && _error.response.data) {
						// @ts-ignore
						return Promise.reject(_error.response.data);
					}
					return Promise.reject(_error);
				}
			}
			if (err.response.status === 403 && err.response.data) {
				return Promise.reject(err.response.data);
			}
		}
		return Promise.reject(err);
	}
);
