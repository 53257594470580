import React from 'react';
import styles from '../styles.module.scss';
import { Clock } from 'components/Clock';

export const InboxTab: React.FC = (): JSX.Element => {
	return (
		<>
			<div className={styles.tabContentHeader}>
				<span></span>
				<span>
					Current UTC time: <Clock />
				</span>
			</div>
			<div className={styles.tabContentMain}></div>
		</>
	);
};
