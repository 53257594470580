import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ISmallModal {
	children?: ReactNode | string;
	isActive: boolean;
	hideModal: () => void;
}

export const SmallModal: React.FC<ISmallModal> = ({
	children,
	hideModal,
	isActive,
}): JSX.Element | null => {
	if (!isActive) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.title}>Add this item to cart?</div>
			<div className={styles.order}>{children}</div>

			<div className={styles.buttons}>
				<button>Yes</button>
				<button onClick={hideModal}>No</button>
			</div>
		</div>
	);
};
