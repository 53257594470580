import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface IGamePageButton {
	image: string;
	onClick?: () => void;
	children?: ReactNode | string;
}

export const GamePageButton: React.FC<IGamePageButton> = ({
	image,
	children,
	onClick,
}) => {
	return (
		<>
			<button
				className={styles.button}
				onClick={onClick}
				style={{ background: image }}
			>
				<img className={styles.icon} src={image} alt='button' />
			</button>
			{children}
		</>
	);
};
