import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';

export const LoginForm: React.FC = () => {
	const onFinish = (values: any) => {
		console.log('Success:', values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			name='basic'
			initialValues={{ remember: true }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete='off'
		>
			<Form.Item
				name='username'
				rules={[{ required: true, message: 'Please input your username!' }]}
			>
				<Input placeholder='username' />
			</Form.Item>

			<Form.Item
				name='password'
				rules={[{ required: true, message: 'Please input your password!' }]}
			>
				<Input.Password placeholder='passowrd' />
			</Form.Item>

			<Form.Item name='remember' valuePropName='checked'>
				<Checkbox>Login automatically in the future</Checkbox>
			</Form.Item>

			<Form.Item>
				<Button type='primary' htmlType='submit'>
					Login
				</Button>
			</Form.Item>
		</Form>
	);
};
