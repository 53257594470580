import React, { useState } from 'react';

export const Clock: React.FC = (): JSX.Element => {
	const [date, setDate] = useState(new Date());

	React.useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		return () => {
			clearInterval(timerID);
		};
	});

	function tick() {
		setDate(new Date());
	}

	return <span>{date.toLocaleTimeString()}</span>;
};
