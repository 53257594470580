import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { Order } from '../../components/Order';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { IOrder } from '../../types/order';
import { fetchPackagesAction } from '../../store/actions/packages';

export const AccountPage: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const orders: IOrder[] = useAppSelector(
		(state) => state.packagesReducer.packages
	);

	useEffect(() => {
		dispatch(fetchPackagesAction());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.overlay}>
				<div className={styles.title}>My account</div>
				<div className={styles.content}>
					<div className={styles.history}>
						<span>Order history</span>
						<div className={styles.orders}>
							{orders.map((order) => {
								return (
									<div className={styles.order} key={order.dataHash}>
										<Order order={order} />
										<div className={styles.orderDate}>02/19/2022</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className={styles.details}>
						<span className={styles.detailsTitle}>Accounts details</span>
						<span className={styles.name}>John Brown</span>
						<span className={styles.location}>United States</span>
					</div>
				</div>
			</div>
		</div>
	);
};
