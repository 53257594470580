import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { IPackage } from '../../types/packages';
import { fetchPackagesAction } from '../../store/actions/packages';
import styles from './styles.module.scss';
import {
	ResourcesContainer,
	PackageContainer,
	CrownsContainer,
} from '../../components';
import { crowns } from './constants/crowns';

export const Purchases: React.FC = (): JSX.Element => {
	const [currentPage, setCurrentPage] = useState<number>(0);

	const setPage = (page: number) => {
		setCurrentPage(page);
	};

	const dispatch = useAppDispatch();
	const packs: IPackage[] = useAppSelector(
		(state) => state.packagesReducer.packages
	);

	useEffect(() => {
		dispatch(fetchPackagesAction());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={styles.container}>
				<div className={styles.main}>
					<ResourcesContainer setPage={setPage} />

					<div className={styles.content}>
						<span className={styles.title}>Purchase Crowns!</span>
						{currentPage ? (
							<PackageContainer packages={packs} />
						) : (
							<CrownsContainer crowns={crowns} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
