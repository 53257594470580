import { createSlice } from '@reduxjs/toolkit';

interface ModalReducer {
	DAILY_REWARDS_MODAL: boolean;
	SUMMARY_MODAL: boolean;
	PURCHASES_MODAL: boolean;
	ADVICES_MODAL: boolean;
}

const initialState: ModalReducer = {
	DAILY_REWARDS_MODAL: false,
	SUMMARY_MODAL: false,
	PURCHASES_MODAL: false,
	ADVICES_MODAL: false,
};

export const modalSlice = createSlice({
	initialState,
	name: 'modal',
	reducers: {
		showDailyRewardsModal(state) {
			state.DAILY_REWARDS_MODAL = true;
		},
		hideDailyRewardsModal(state) {
			state.DAILY_REWARDS_MODAL = false;
		},
		showSummaryModal(state) {
			state.SUMMARY_MODAL = true;
		},
		hideSummaryModal(state) {
			state.SUMMARY_MODAL = false;
		},
		showAdvicesModal(state) {
			state.ADVICES_MODAL = true;
		},
		hideAdvicesModal(state) {
			state.ADVICES_MODAL = false;
		},
		showPurchasesModal(state) {
			state.PURCHASES_MODAL = true;
		},
		hidePurchasesModal(state) {
			state.PURCHASES_MODAL = false;
		},
	},
});

export default modalSlice.reducer;
