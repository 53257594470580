import { combineReducers, configureStore } from '@reduxjs/toolkit';
import packagesReducer from 'store/reducers/packages';
import modalReducer from 'store/reducers/modal';
import accountReducer from 'store/reducers/account';

const rootReducer = combineReducers({
	packagesReducer,
	modalReducer,
	accountReducer,
});

export const setupStore = () =>
	configureStore({
		reducer: rootReducer,
	});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
