import React from 'react';
import styles from './styles.module.scss';
import crown from '../../images/little-crown.png';
import { IDailyReward } from '../../types/dailyReward';

export const DailyReward: React.FC<IDailyReward> = ({
	countRewards,
	buttonText,
	title,
	image,
	isLocked,
	isLastReward,
}): JSX.Element => {
	return (
		<>
			<div
				className={
					isLastReward
						? `${styles.wrapper} ${styles.lastReward}`
						: styles.wrapper
				}
			>
				<div
					className={
						isLocked ? `${styles.locked} ${styles.container}` : styles.container
					}
				>
					<img
						src={image}
						alt='reward'
						className={
							isLocked ? `${styles.lockedChest} ${styles.chest}` : styles.chest
						}
					/>
					<div className={styles.title}>{title}</div>
					<div className={styles.reward}>
						<img src={crown} alt='crown' className={styles.crown} />
						<div className={styles.countRewards}>{countRewards}</div>
					</div>
				</div>
				<div>
					<button
						className={
							isLocked
								? `${styles.watchVideo} ${styles.lockedButton}`
								: styles.watchVideo
						}
					>
						{buttonText}
					</button>
				</div>
			</div>
		</>
	);
};
