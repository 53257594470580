export const crowns = [
	{
		dataHash: '6ae83d0707902a8e3f2da8e8ad0c9bf8',
		iTunes: 'com.nemesis.salesystem.010a',
		google: 'com.nemesis.salesystem.010a',
		costAmount: 999,
		resourceType: 'CASH',
		saleId: -1,
		id: 'VillaStepUp_053022_2_A',
		saleName: 'Villa Sale!',
		nextSale: 1,
		subtitle: null,
		bonusBadge: {
			amount: 0,
			msgID: '',
		},
		purchaseLimit: 1,
		saleItemsV2: [
			{
				key: 'event_information_bomber',
				amount: 5,
				level: 1,
				payload: null,
				isHighlighted: true,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
			{
				key: 'archive_speedup_generic!4',
				amount: 5,
				level: 1,
				payload: null,
				isHighlighted: false,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
			{
				key: 'vip_points',
				amount: 20,
				level: 1,
				payload: null,
				isHighlighted: false,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
		],
		token:
			'{"v":100,"t":"\\bÃÂDWÃ«\\u0013Â Ã¼b.\\u0005PÃ\\u0010)\\u000BÂÃ®Ã½.jÂ Ã+nÃ¨&\\u0010ÃºÃÂÃÃ`0ÃÃµÃÃAPÃÃ£Â¾7Fi-m\\u0002=Ã¤LÃµÃ¦bÂ Ã0nÃª\\r-Ã¥\'\\u0005OÂ¨y<g|Â³\\u001CÃLÂv&ZÂ¶ÂÃÂ+Â¤\\u0019x\\u001F)vzÂ52ÃÂ Ã¼h\\u000FÃÂ¿Â\\u001E\\u000F\\u0007ÂÃÃ!hÂÂ¸Ã~GÃ+\\u0016a\\u000BÂ¦0\\u0003ÂN&Ã¤-ÃÂ«p)}ÂÃv\\u0014Ã_ÃÂ¨RÃ±Ã;Â±@k9Ã«Ã¡EÃ¬ÃÃ¼ÂÂ¨Ã ÂÂ¢Â¢CB\\u0017ÃÃªÃÂÃ-Ã»"}',
		highlightedTag: 'event_information_bomber',
		highlightedChest: null,
		secondHighlightedTag: null,
		secondHighlightedChest: null,
		oldAmount: -1,
		bannerText: null,
	},
	{
		dataHash: '885cacfca1e09a3c741353674b521710',
		iTunes: 'com.nemesis.salesystem.020c',
		google: 'com.nemesis.salesystem.020c',
		costAmount: 1999,
		resourceType: 'CASH',
		saleId: -1,
		id: 'VillaStepUp_053022_2_B',
		saleName: 'Villa Sale!',
		nextSale: 2,
		subtitle: null,
		bonusBadge: {
			amount: 0,
			msgID: '',
		},
		purchaseLimit: 1,
		saleItemsV2: [
			{
				key: 'archive_speedup_generic!5',
				amount: 20,
				level: 1,
				payload: null,
				isHighlighted: true,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
			{
				key: 'vip_points',
				amount: 40,
				level: 1,
				payload: null,
				isHighlighted: false,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
		],
		token:
			'{"v":100,"t":"\\bÃÂDWÃ«\\u0013Â Ã¼b.\\u0005PÃ\\u0010)\\u000BÂÃ®Ã½.jÂ Ã+nÃ¨&\\u0010ÃºÃÂÃÃ`0ÃÃµÃÃAPÃÃ£Â¾7Fi-m\\u0002=Ã¤LÃµÃ¦bÂ Ã0nÃª\\r-Ã¥\'\\u0005OÂ¨y<g|Â³\\u001CÃLÂv&ZÂ¶ÂÃÂ+Â¤\\u0019x\\u001F)vzÂ52ÃÂ Ã¼h\\u000FÃÂ¿Â\\u001E\\u000F\\u0007ÂÃ%Â²<ÂÃÂÃ°\\u0018ÂÂºÃ¸GÂÂÃÂÂN&Ã¤-ÃÂ«p)}ÂÃv\\u0014Ã_ÃÂ¨RÃ±Ã;Â±@k9Ã«Ã¡EÃ¬ÃÃ¼ÂÂ¨Ã ÂÂ¢Â¢CB\\u0017ÃÃªÃÂÃ-Ã»"}',
		highlightedTag: 'archive_speedup_generic!5',
		highlightedChest: null,
		secondHighlightedTag: null,
		secondHighlightedChest: null,
		oldAmount: -1,
		bannerText: null,
	},
	{
		dataHash: '1aacdd92adba7cc62b866f484b37e58f',
		iTunes: 'com.nemesis.salesystem.025',
		google: 'com.nemesis.salesystem.025',
		costAmount: 2499,
		resourceType: 'CASH',
		saleId: -1,
		id: 'VillaStepUp_053022_2_C',
		saleName: 'Villa Sale!',
		nextSale: -1,
		subtitle: null,
		bonusBadge: {
			amount: 0,
			msgID: '',
		},
		purchaseLimit: 1,
		saleItemsV2: [
			{
				key: 'global_expiration_event_villa',
				amount: 1,
				level: 1,
				payload:
					'{"payloadType":"GLOBAL_EXPIRATION_BUILDING","data":"{\\"expirationDate\\":1655128800}"}',
				isHighlighted: true,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
			{
				key: 'archive_upgrade_wall',
				amount: 20,
				level: 1,
				payload: null,
				isHighlighted: false,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
			{
				key: 'vip_points',
				amount: 50,
				level: 1,
				payload: null,
				isHighlighted: false,
				highlightedContent: null,
				strikethroughAmount: 0,
				bannerText: null,
			},
		],
		token:
			'{"v":100,"t":"\\bÃÂDWÃ«\\u0013Â Ã¼b.\\u0005PÃ\\u0010)\\u000BÂÃ®Ã½.jÂ Ã+nÃ¨&\\u0010ÃºÃÂÃÃ`0ÃÃµÃÃAPÃÃ£Â¾7Fi-m\\u0002=Ã¤LÃµÃ¦bÂ Ã0nÃª\\r-Ã¥\'\\u0005OÂ¨y<g|Â³\\u001CÃLÂv&ZÂ¶ÂÃÂ+Â¤\\u0019x\\u001F)vzÂ52ÃÂ Ã¼h\\u000FÃÂ¿Â\\u001E\\u000F\\u0007ÂÃÂÂÃÃÃÂ¸Ã\\u0013\\bxÃ«j9gÂNÂN&Ã¤-ÃÂ«p)}ÂÃv\\u0014Ã_ÃÂ¨RÃ±Ã;Â±@k9Ã«Ã¡EÃ¬ÃÃ¼ÂÂ¨Ã ÂÂ¢Â¢CB\\u0017ÃÃªÃÂÃ-Ã»"}',
		highlightedTag: null,
		highlightedChest: null,
		secondHighlightedTag: null,
		secondHighlightedChest: null,
		oldAmount: -1,
		bannerText: null,
	},
];
