import { packagesSlice } from 'store/reducers/packages';
import { AppDispatch } from 'store';
import { IPackage } from 'types/packages';
import { fetchPackages } from 'services/packages';

export const fetchPackagesAction = () => async (dispatch: AppDispatch) => {
	try {
		// dispatch(packagesSlice.actions.productFetching());
		const products = await fetchPackages();

		dispatch(
			packagesSlice.actions.addPackages({ packages: products.data.packages })
		);
	} catch (e) {
		// productSlice.actions.productFetchingError(e.toString());
	}
};
