import React, { useState } from 'react';
import { IPackage } from 'types/packages';
import { PackageComponent } from 'components/PackageComponent';
import styles from './styles.module.css';
import { SmallModal } from '../UI/SmallModal';

interface IPackageContainer {
	packages?: IPackage[];
}

export const PackageContainer: React.FC<IPackageContainer> = ({ packages }) => {
	const [isActive, setActive] = useState<boolean>(false);
	const [selectedPack, setSelectedPack] = useState<number>(0);

	const hideModal = () => {
		setActive(false);
	};
	const showModal = () => {
		setActive(true);
	};

	const setPack = (index: number) => {
		setSelectedPack(index);
	};

	return (
		<div className={styles.container}>
			{packages?.map((pack: IPackage, index) => (
				<PackageComponent
					key={pack.dataHash}
					packageItem={pack}
					addToModal={() => {
						setPack(index);
						showModal();
					}}
				/>
			))}
			<SmallModal isActive={isActive} hideModal={hideModal}>
				<PackageComponent
					addToModal={() => {}}
					packageItem={packages ? packages[selectedPack] : undefined}
				/>
			</SmallModal>
		</div>
	);
};
