import React from 'react';
import styles from './styles.module.scss';
import { GoalProps } from '../../types/goals';
import crown from '../../images/little-crown.png';

export const Goal: React.FC<GoalProps> = ({ goal }): JSX.Element => {
	return (
		<div className={styles.goal}>
			<div className={styles.header}>
				<div className={styles.title}>{goal.title}</div>
				<div className={styles.score}>
					{goal.score}/{goal.totalScore}
				</div>
			</div>

			<div className={styles.description}>
				{goal.description}
				<img className={styles.crown} src={crown} alt='crown' />
				<img className={styles.crown} src={crown} alt='crown' />
			</div>
		</div>
	);
};
