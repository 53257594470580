export const achievements = [
	{
		title: 'Lumberjack',
		description:
			'Orci varius natoque penatibus et magnis dis parturient montes, nisi vitae maximus tincidun',
		score: '0',
		totalScore: '100',
		icon: '',
		reward: '5',
	},
	{
		title: 'Lumberjack',
		description:
			'Orci varius natoque penatibus et magnis dis parturient montes, nisi vitae maximus tincidun',
		score: '0',
		totalScore: '100',
		icon: '',
		reward: '5',
	},
	{
		title: 'Lumberjack',
		description:
			'Orci varius natoque penatibus et magnis dis parturient montes, nisi vitae maximus tincidun',
		score: '0',
		totalScore: '100',
		icon: '',
		reward: '5',
	},
	{
		title: 'Lumberjack',
		description:
			'Orci varius natoque penatibus et magnis dis parturient montes, nisi vitae maximus tincidun',
		score: '0',
		totalScore: '100',
		icon: '',
		reward: '5',
	},
	//  {
	//     title: 'Lumberjack',
	//     description:'Orci varius natoque penatibus et magnis dis parturient montes, nisi vitae maximus tincidun',
	//     score: '0',
	//     totalScore: '100',
	//     icon: '',
	//     reward: '5'
	// },
];
