import { AuthTabs } from 'components';
import React from 'react';
import styles from './styles.module.scss';

console.log(styles);

export const Login: React.FC = (): JSX.Element => {
	return (
		<div className={styles.container}>
			<AuthTabs />
		</div>
	);
};
