export enum Tokens {
	ACCESS = 'accessToken',
	REFRESH = 'refreshToken',
}

export const getLocalAccessToken = () => {
	return window.localStorage.getItem(Tokens.ACCESS);
};

export const setLocalAccessToken = (token: string) => {
	window.localStorage.setItem(Tokens.ACCESS, token);
};

export const getLocalRefreshToken = () => {
	return window.localStorage.getItem(Tokens.REFRESH);
};

export const setLocalRefreshToken = (token: string) => {
	window.localStorage.setItem(Tokens.REFRESH, token);
};

export const removeLocalItem = (name: string) => {
	window.localStorage.removeItem(name);
};
