import React, { useCallback, useState } from 'react';
import styles from '../styles.module.scss';
import { GamePageButton } from '../../UI/GamePageButton';
import achievements from '../../../images/gamePageButtons/achievements.png';
import { AchievementsGoalsTabs } from '../../AchievementsGoalsTabs';

export const AchievementsButton: React.FC = () => {
	const [isActiveTabs, setActiveTabs] = useState<Boolean>(false);

	const openTabs = useCallback(() => {
		setActiveTabs(true);
	}, []);

	const hideTabs = useCallback(() => {
		setActiveTabs(false);
	}, []);

	return (
		<>
			<div className={styles.achievements}>
				<GamePageButton image={achievements} onClick={openTabs}>
					{isActiveTabs && <AchievementsGoalsTabs hideTabs={hideTabs} />}
				</GamePageButton>
			</div>
		</>
	);
};
