import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPackage } from 'types/packages';

interface PackagesReducer {
	packages: IPackage[];
}

const initialState: PackagesReducer = {
	packages: [],
};

export const packagesSlice = createSlice({
	initialState,
	name: 'packages',
	reducers: {
		addPackages(
			state,
			action: PayloadAction<{
				packages: IPackage[];
			}>
		) {
			state.packages = action.payload.packages;
		},
	},
});

export default packagesSlice.reducer;
