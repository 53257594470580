import React from 'react';
import styles from '../styles.module.scss';
import { GamePageButton } from '../../UI/GamePageButton';
import advices from '../../../images/gamePageButtons/advices.png';
import { useAppDispatch } from '../../../hooks/redux';
import { showAdvicesModalAction } from '../../../store/actions/modal';
import { AdvisesModal } from '../../AdvisesModal';

export const AdvicesButton: React.FC = () => {
	const dispatch = useAppDispatch();
	return (
		<div className={styles.advices}>
			<GamePageButton
				image={advices}
				onClick={() => {
					dispatch(showAdvicesModalAction());
				}}
			>
				<AdvisesModal />
			</GamePageButton>
		</div>
	);
};
