import React, { useState } from 'react';
import styles from './style.module.scss';
import { tabContent, tabs } from './constants/tabs';

export const AdvicesTabs: React.FC = (): JSX.Element => {
	const [currentTab, setCurrentTab] = useState<number>(0);

	const toggleTab = (index: number): void => {
		setCurrentTab(index);
	};

	return (
		<div className={styles.tabsContainer}>
			<div className={styles.tabsHeader}>
				{tabs.map((tab, index) => {
					return (
						<button
							key={index}
							onClick={() => toggleTab(index)}
							className={`${styles.tab} ${
								index === currentTab && styles.activeTab
							}`}
						>
							{tab}
						</button>
					);
				})}
			</div>
			<div className={styles.tabContent}>{tabContent[currentTab]}</div>
		</div>
	);
};
