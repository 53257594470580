import React from 'react';
import Modal from 'components/UI/Modal';
import styles from './styles.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { hideAdvicesModalAction } from 'store/actions/modal';
import { AdvicesTabs } from 'components/AdvicesTabs';

export const AdvisesModal: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isModalOpen = useAppSelector(
		(state) => state.modalReducer.ADVICES_MODAL
	);

	return (
		<Modal
			dispatchHideModal={() => {
				dispatch(hideAdvicesModalAction());
			}}
			isModalOpen={isModalOpen}
		>
			<div className={styles.container}>
				<AdvicesTabs />
			</div>
		</Modal>
	);
};
