import { modalSlice } from 'store/reducers/modal';
import { AppDispatch } from 'store';

export const hideDailyRewardsModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.hideDailyRewardsModal());
};

export const showDailyRewardsModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.showDailyRewardsModal());
};

export const hideSummaryModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.hideSummaryModal());
};

export const showSummaryModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.showSummaryModal());
};

export const hidePurchasesModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.hidePurchasesModal());
};

export const showPurchasesModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.showPurchasesModal());
};

export const hideAdvicesModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.hideAdvicesModal());
};

export const showAdvicesModalAction = () => (dispatch: AppDispatch) => {
	dispatch(modalSlice.actions.showAdvicesModal());
};
