import React from 'react';
import styles from './styles.module.scss';

export const ResourcesComponent: React.FC<{
	setPage: (page: number) => void;
}> = ({ setPage }): JSX.Element => {
	return (
		<div className={styles.container}>
			<span className={styles.title}>Currency</span>
			<button
				onClick={() => {
					return setPage(0);
				}}
				className={styles.crown}
			>
				Crowns
			</button>
			<button
				onClick={() => {
					return setPage(1);
				}}
				className={styles.resource}
			>
				Resources
			</button>
		</div>
	);
};
