import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import { GoalsList } from '../GoalsList';
import { goals } from './constants/goals';
import { tabs } from './constants/tabs';
import { achievements } from './constants/achievements';
import { AchievementsList } from 'components/AchievementsList';
import { useOutsideClick } from 'hooks/useOutsideClick';

const tabContent = [
	<GoalsList goals={goals} />,
	<AchievementsList achievements={achievements} />,
];

interface IAchievementsGoalsTabs {
	hideTabs: () => void;
}

export const AchievementsGoalsTabs: React.FC<IAchievementsGoalsTabs> = ({
	hideTabs,
}): JSX.Element => {
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, hideTabs);

	const [currentTab, setCurrentTab] = useState<number>(0);

	const toggleTab = (index: number): void => {
		setCurrentTab(index);
	};

	return (
		<div ref={wrapperRef} className={styles.tabsContainer}>
			<div className={styles.tabsHeader}>
				{tabs.map((tab, index) => {
					return (
						<button
							key={index}
							onClick={() => toggleTab(index)}
							className={`${styles.tab} ${
								index === currentTab && styles.activeTab
							}`}
						>
							{tab}
						</button>
					);
				})}
			</div>
			<div className={styles.tabContent}>{tabContent[currentTab]}</div>
		</div>
	);
};
