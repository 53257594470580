import React, { useState } from 'react';
import styles from '../PackageContainer/styles.module.css';
import { SmallModal } from '../UI/SmallModal';
import { ICrowns } from '../../types/crowns';
import { CrownsComponent } from '../CrownsComponent';

interface ICrownsContainer {
	crowns?: ICrowns[];
}

export const CrownsContainer: React.FC<ICrownsContainer> = ({
	crowns,
}): JSX.Element => {
	const [isActive, setActive] = useState<boolean>(false);
	const [selectedPack, setSelectedPack] = useState<number>(0);

	const hideModal = () => {
		setActive(false);
	};
	const showModal = () => {
		setActive(true);
	};

	const setPack = (index: number) => {
		setSelectedPack(index);
	};

	return (
		<>
			<div className={styles.container}>
				{crowns?.map((crown: ICrowns, index) => (
					<CrownsComponent
						key={crown.dataHash}
						crownsItem={crown}
						addToModal={() => {
							setPack(index);
							showModal();
						}}
					/>
				))}
				<SmallModal isActive={isActive} hideModal={hideModal}>
					<CrownsComponent
						addToModal={() => {}}
						crownsItem={crowns ? crowns[selectedPack] : undefined}
					/>
				</SmallModal>
			</div>
		</>
	);
};
