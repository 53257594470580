import React from 'react';
import styles from '../styles.module.scss';
import { GamePageButton } from '../../UI/GamePageButton';
import summary from '../../../images/gamePageButtons/summary.png';
import { useAppDispatch } from '../../../hooks/redux';
import { showSummaryModalAction } from '../../../store/actions/modal';
import { SummaryModal } from '../../SummaryModal';

export const SummaryButton: React.FC = () => {
	const dispatch = useAppDispatch();

	return (
		<div className={styles.summary}>
			<GamePageButton
				onClick={() => {
					dispatch(showSummaryModalAction());
				}}
				image={summary}
			>
				<SummaryModal />
			</GamePageButton>
		</div>
	);
};
