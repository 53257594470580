import { Tabs } from 'antd';
import React from 'react';
import { LoginForm } from '../LoginForm';
import { RegisterForm } from '../RegisterForm';

import styles from './styles.module.scss';

const { TabPane } = Tabs;

export const AuthTabs: React.FC = () => {
	const onChange = (key: string) => {
		console.log(key);
	};

	return (
		<div className={styles.overlay}>
			<Tabs defaultActiveKey='1' onChange={onChange}>
				<TabPane tab='Login' key='1'>
					<LoginForm />
				</TabPane>
				<TabPane tab='Create an account' key='2'>
					<RegisterForm />
				</TabPane>
			</Tabs>
		</div>
	);
};
