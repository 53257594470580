import React from 'react';
import { Goal } from '../Goal';
import { IGoalsListProps } from '../../types/goals';
import styles from './styles.module.scss';
import crown from '../../images/little-crown.png';

export const GoalsList: React.FC<IGoalsListProps> = ({
	goals,
}): JSX.Element => {
	return (
		<>
			<div className={styles.goalsList}>
				{goals.map((goal, index) => {
					return <Goal key={index} goal={goal} />;
				})}
			</div>
			<div className={styles.tabsFooter}>
				<span>
					Want more free crowns{' '}
					<img className={styles.crown} src={crown} alt='crown' />?
				</span>
				<button className={styles.footerBtn}>See offers</button>
			</div>
		</>
	);
};
