import React from 'react';
import { upgradesList } from '../constants/upgradesList';
import { Upgrade } from '../Upgrade';
import styles from './style.module.scss';
import upgradeImage from 'images/upgrade-img.png';

export const UpgradesList: React.FC = (): JSX.Element => {
	return (
		<div className={styles.upgradeList}>
			{upgradesList.map((upgrade, index) => {
				return (
					<Upgrade
						key={index}
						title={upgrade.title}
						cost={upgrade.cost}
						image={upgradeImage}
						level={upgrade.level}
						waitTime={upgrade.waitTime}
					/>
				);
			})}
			<div className={styles.lockedUpgrade}>
				Build a Library in the Classical Age
			</div>
		</div>
	);
};
