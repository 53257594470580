import React from 'react';
import { Progress } from 'antd';
import styles from './styles.module.scss';
import { IAchievementProps } from 'types/achievemetns';
import achievementImg from 'images/gamePageButtons/achievements.png';
import crown from 'images/little-crown.png';

export const Achievement: React.FC<IAchievementProps> = ({
	achievement,
}): JSX.Element => {
	return (
		<div className={styles.achievement}>
			{/*<img src={achievement.icon} alt={achievement.title}/> //!todo src from data*/}
			<img
				className={styles.image}
				src={achievementImg}
				alt={achievement.title}
			/>
			<div className={styles.header}>
				<div className={styles.title}>{achievement.title}</div>
				<div className={styles.description}>{achievement.description}</div>
			</div>
			<div className={styles.progress}>
				<div className={styles.progressBar}>
					<Progress percent={0} showInfo={false} />
				</div>
				<div className={styles.score}>
					{achievement.score}/{achievement.totalScore}
				</div>

				<div className={styles.reward}>
					Reward: <span>{achievement.reward}</span>
					<img src={crown} alt='crown' className={styles.crown} />
				</div>
			</div>
		</div>
	);
};
