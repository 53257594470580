import React, { ReactNode } from 'react';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';

interface ModalProps {
	dispatchHideModal: () => void;
	isModalOpen: boolean;
	children: ReactNode | string;
}

const Modal: React.FC<ModalProps> = ({
	dispatchHideModal,
	isModalOpen,
	children,
}) => {
	if (!isModalOpen) {
		return null;
	}

	const modalRoot = document.querySelector('#root');

	const modal = (
		<div className={styles.overlay}>
			<div className={styles.modal}>
				<button className={styles.close} onClick={dispatchHideModal}>
					&#10005; {/* HTML code for a multiplication sign */}
				</button>
				{children}
			</div>
		</div>
	);

	return ReactDOM.createPortal(modal, modalRoot as HTMLElement);
};

export default Modal;
