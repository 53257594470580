export const goals = [
	{
		title: 'Raid the barbarians',
		description:
			'Curabitur in augue erat. Vestibulum in fermentum ante, sit amet consectetur neque. Maecenas tempor nisl sollicitudin, blandit sapien ut, fermentum metus.',
		score: '0',
		totalScore: '300',
	},
	{
		title: 'Raid the barbarians',
		description:
			'Curabitur in augue erat. Vestibulum in fermentum ante, sit amet consectetur neque. Maecenas tempor nisl sollicitudin, blandit sapien ut, fermentum metus.',
		score: '0',
		totalScore: '300',
	},
	{
		title: 'Raid the barbarians',
		description:
			'Curabitur in augue erat. Vestibulum in fermentum ante, sit amet consectetur neque. Maecenas tempor nisl sollicitudin, blandit sapien ut, fermentum metus.',
		score: '0',
		totalScore: '300',
	},
];
