import { IDailyReward } from '../../../types/dailyReward';

export const dailyRewards = [
	{
		title: 'Prize 1',
		countRewards: '2',
		isLocked: false,
	},
	{
		title: 'Prize 2',
		countRewards: '3',
		isLocked: true,
	},
	{
		title: 'Prize 3',
		countRewards: '5',
		isLocked: true,
	},
	{
		title: 'Prize 4',
		countRewards: '8',
		isLocked: true,
	},
	{
		title: 'Prize 5',
		countRewards: '15',
		isLocked: true,
	},
	{
		title: 'Prize 6',
		countRewards: '20',
		isLocked: true,
	},
	{
		title: 'Prize 7',
		countRewards: '25',
		isLocked: true,
	},
	{
		title: 'Prize 8',
		countRewards: '30',
		isLocked: true,
	},
] as IDailyReward[];
