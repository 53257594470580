import React from 'react';
import { Achievement } from '../Achievement';
import { IAchievementsListProps } from '../../types/achievemetns';
import styles from './styles.module.scss';

export const AchievementsList: React.FC<IAchievementsListProps> = ({
	achievements,
}): JSX.Element => {
	return (
		<div className={styles.container}>
			<div className={styles.achievementsTotal}></div>
			<div className={styles.achievementsList}>
				{achievements.map((achievement, index) => {
					return <Achievement key={index} achievement={achievement} />;
				})}
			</div>
			<div className={styles.achievementsFooter}>
				<button className={styles.footerBtn}>Achievements</button>
			</div>
		</div>
	);
};
