import { httpClient } from 'utils/httpClient';

export const fetchPackages = () => {
	const apiUrl = 'https://dominationsapi.vercel.app';
	return httpClient.get(`${apiUrl}/sales.json`);
};

export const PackagesService = {
	fetchPackages,
};
