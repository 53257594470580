import React from 'react';
import styles from '../styles.module.scss';
import { GamePageButton } from '../../UI/GamePageButton';
import dailyReward from '../../../images/gamePageButtons/daily-reward.png';
import { DailyRewardsModal } from '../../DailyRewardsModal';
import { showDailyRewardsModalAction } from '../../../store/actions/modal';
import { useAppDispatch } from '../../../hooks/redux';

export const DailyRewardButton: React.FC = () => {
	const dispatch = useAppDispatch();

	return (
		<div className={styles.dailyReward}>
			<GamePageButton
				onClick={() => {
					dispatch(showDailyRewardsModalAction());
				}}
				image={dailyReward}
			>
				<DailyRewardsModal />
			</GamePageButton>
		</div>
	);
};
