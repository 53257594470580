import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { setupStore } from 'store';
import { MainPage, Login, Game } from './containers';

import 'antd/dist/antd.min.css';
import { HeaderComponent } from './components';
import { Cart } from './components/Cart';
import { AccountPage } from './containers/AccountPage';

const store = setupStore();

export const App: React.FC = (): JSX.Element => {
	return (
		<>
			<Provider store={store}>
				<BrowserRouter>
					<HeaderComponent />
					<Routes>
						<Route path='/' element={<MainPage />} />
						<Route path='/login' element={<Login />} />
						<Route path='/game' element={<Game />} />
						<Route path='/cart' element={<Cart />} />
						<Route path='/account' element={<AccountPage />} />
					</Routes>
				</BrowserRouter>
			</Provider>
		</>
	);
};
