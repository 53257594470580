import React from 'react';
import styles from './styles.module.scss';
import { GamePageButtons } from 'components/GamePageButtons';

export const Game: React.FC = (): JSX.Element => {
	return (
		<div className={styles.container}>
			<GamePageButtons />
		</div>
	);
};
