import React from 'react';
import Modal from '../UI/Modal';
import styles from './styles.module.scss';
import { SummaryTabs } from '../SummaryTabs';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { hideSummaryModalAction } from '../../store/actions/modal';

export const SummaryModal: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isModalOpen = useAppSelector(
		(state) => state.modalReducer.SUMMARY_MODAL
	);

	return (
		<Modal
			dispatchHideModal={() => {
				dispatch(hideSummaryModalAction());
			}}
			isModalOpen={isModalOpen}
		>
			<div className={styles.container}>
				<SummaryTabs />
			</div>
		</Modal>
	);
};
