import React from 'react';
import Modal from '../UI/Modal';
import styles from './styles.module.scss';
import chest from '../../images/chest.png';
import { dailyRewards } from './constants/dailyReward';
import { DailyReward } from '../DailyReward';
import { IDailyReward } from '../../types/dailyReward';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { hideDailyRewardsModalAction } from '../../store/actions/modal';

export const DailyRewardsModal: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isModalOpen = useAppSelector(
		(state) => state.modalReducer.DAILY_REWARDS_MODAL
	);

	const rewards = [...dailyRewards];
	const lastReward = rewards.pop();

	const setButtonText = (array: IDailyReward[], index: number): string => {
		if (!index) {
			return 'Watch';
		} else {
			return 'Watch video to unlock';
		}
	};

	return (
		<Modal
			isModalOpen={isModalOpen}
			dispatchHideModal={() => {
				dispatch(hideDailyRewardsModalAction());
			}}
		>
			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.title}>Daily Rewards</div>
					<div className={styles.subtitle}>
						Watch videos to claim daily prizes!
					</div>
					<div className={styles.rewardsList}>
						<div className={styles.rewards}>
							{rewards.map((reward, index, array) => {
								return (
									<DailyReward
										key={index}
										title={reward.title}
										countRewards={reward.countRewards}
										buttonText={setButtonText(array, index)}
										image={chest}
										isLocked={reward.isLocked}
										isLastReward={false}
									/>
								);
							})}
						</div>
						<div className={styles.lastReward}>
							<DailyReward
								title={lastReward?.title}
								countRewards={lastReward?.countRewards}
								buttonText={'Watch all videos daily for unlock'}
								image={chest}
								isLocked={lastReward?.isLocked}
								isLastReward={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
