import React from 'react';
import styles from './styles.module.scss';
import Tresure from '../../images/tresure.png';
import { IOrder } from '../../types/order';

export const Order: React.FC<{
	order: IOrder;
}> = ({ order }): JSX.Element => {
	return (
		<div className={styles.order}>
			<div className={styles.orderItem}>
				<span className={styles.orderItemTitle}>{order?.saleName}</span>
				<img src={Tresure} alt='Tresure' />
				<button>{order?.costAmount} $</button>
			</div>
			<div className={styles.title}>{order?.saleName}</div>
			<div className={styles.cost}>{order?.costAmount} $</div>
		</div>
	);
};
