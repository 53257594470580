import React from 'react';
import styles from './style.module.scss';
import clock from 'images/clock.png';
import coin from 'images/coin.png';
import human from 'images/human.png';
import { IUpgrade } from 'types/advices';

export const Upgrade: React.FC<IUpgrade> = ({
	cost,
	title,
	image,
	level,
	waitTime,
}): JSX.Element => {
	return (
		<div className={styles.upgradeContainer}>
			<img src={image} alt='advice' className={styles.upgradeImg} />
			<div className={styles.upgradeInfo}>
				<span className={styles.title}>{title}</span>
				<span className={styles.level}>Level {level}</span>
				<div className={styles.time}>
					<img src={clock} alt='clock' className={styles.icon} />
					{waitTime}
				</div>
			</div>
			<button className={styles.upgradeBtn}>
				<div>
					{cost.coins}
					<img src={coin} alt='coin' className={styles.icon} />
				</div>
				<div>
					{cost.humans}
					<img src={human} alt='human' className={styles.icon} />
				</div>
			</button>
		</div>
	);
};
