import { createSlice } from '@reduxjs/toolkit';

interface AccountReducer {
	isAuthorized: boolean;
}

const initialState: AccountReducer = {
	isAuthorized: true,
};

export const accountSlice = createSlice({
	initialState,
	name: 'account',
	reducers: {
		logOut(state) {
			state.isAuthorized = false;
		},
	},
});

export default accountSlice.reducer;
