import { IUpgrade } from 'types/advices';

export const upgradesList: IUpgrade[] = [
	{
		title: 'Mill',
		level: '1',
		waitTime: '3m',
		image: 'url',
		cost: {
			coins: '150',
			humans: '4',
		},
	},
	{
		title: 'Mill',
		level: '1',
		waitTime: '3m',
		image: 'url',
		cost: {
			coins: '150',
			humans: '4',
		},
	},
	{
		title: 'Mill',
		level: '1',
		waitTime: '3m',
		image: 'url',
		cost: {
			coins: '150',
			humans: '4',
		},
	},
	{
		title: 'Mill',
		level: '1',
		waitTime: '3m',
		image: 'url',
		cost: {
			coins: '150',
			humans: '4',
		},
	},
	{
		title: 'Mill',
		level: '1',
		waitTime: '3m',
		image: 'url',
		cost: {
			coins: '150',
			humans: '4',
		},
	},
];
