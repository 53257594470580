import { Button, Form, Input } from 'antd';
import React from 'react';

export const RegisterForm: React.FC = () => {
	const onFinish = (values: any) => {
		console.log('Success:', values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			name='basic'
			initialValues={{ remember: true }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete='off'
		>
			<Form.Item
				name='email'
				rules={[{ required: true, message: 'Please input your email!' }]}
			>
				<Input placeholder='email' />
			</Form.Item>

			<Form.Item
				name='password'
				rules={[{ required: true, message: 'Please input your password!' }]}
			>
				<Input.Password placeholder='password' />
			</Form.Item>

			<Form.Item
				name='fullName'
				rules={[{ required: true, message: 'Please input your fullname!' }]}
			>
				<Input placeholder='fullName' />
			</Form.Item>

			<Form.Item>
				<Button type='primary' htmlType='submit'>
					Create Account
				</Button>
			</Form.Item>
		</Form>
	);
};
