import React from 'react';
import { IPackage } from 'types/packages';
import Tresure from 'images/tresure.png';
import styles from './styles.module.scss';

export const PackageComponent: React.FC<{
	packageItem?: IPackage;
	addToModal: () => void;
}> = ({ packageItem, addToModal }): JSX.Element => {
	return (
		<div className={styles.container}>
			<span className={styles.title}>{packageItem?.saleName}</span>
			<img src={Tresure} alt='Tresure' />
			<button onClick={addToModal}>{packageItem?.costAmount} $</button>
		</div>
	);
};
