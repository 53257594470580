import React from 'react';
import styles from '../styles.module.scss';

export const InventoryTab: React.FC = (): JSX.Element => {
	return (
		<>
			<div className={styles.tabContentHeader}>
				<span></span>
				<span></span>
			</div>
			<div className={styles.tabContentMain}></div>
		</>
	);
};
