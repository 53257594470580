import React from 'react';
import Crown from 'images/little-crown.png';
import styles from './styles.module.scss';
import { ICrowns } from '../../types/crowns';

export const CrownsComponent: React.FC<{
	crownsItem?: ICrowns;
	addToModal: () => void;
}> = ({ crownsItem, addToModal }): JSX.Element => {
	return (
		<div className={styles.container}>
			<span className={styles.title}>{crownsItem?.saleName}</span>
			<img src={Crown} alt='crown' />
			<button onClick={addToModal}>{crownsItem?.costAmount} $</button>
		</div>
	);
};
