import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchPackagesAction } from '../../store/actions/packages';
import { Order } from '../Order';
import { IOrder } from '../../types/order';

export const Cart: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const orders: IOrder[] = useAppSelector(
		(state) => state.packagesReducer.packages
	);

	useEffect(() => {
		dispatch(fetchPackagesAction());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.overlay}>
				<div className={styles.title}>Your cart</div>
				<div className={styles.orders}>
					{orders.map((order) => {
						return <Order key={order.dataHash} order={order} />;
					})}
				</div>
				<div className={styles.total}>
					<span>Total</span>
					<div>
						${' '}
						{orders.reduce((total, order) => {
							// @ts-ignore
							return (total += order?.costAmount);
						}, 0)}
					</div>
				</div>
				<div className={styles.checkout}>
					<button> Proceed to checkout</button>
				</div>
			</div>
		</div>
	);
};
