import React from 'react';
import { UpgradesList } from '../UpgradesList';
import styles from './styles.module.scss';

export const AdvisorTab: React.FC = (): JSX.Element => {
	return (
		<>
			<div className={styles.header}>
				<div className={styles.title}>Recommended upgrades</div>
			</div>
			<UpgradesList />
		</>
	);
};
