import React from 'react';
import { AchievementsButton } from './AchievementsButton';
import { DailyRewardButton } from './DailyRewardButton';
import { SummaryButton } from './SummaryButton';
import { PurchasesButton } from './PurchasesButton';
import { AdvicesButton } from './AdvicesButton';

export const GamePageButtons: React.FC = () => {
	return (
		<>
			<AchievementsButton />
			<DailyRewardButton />
			<SummaryButton />
			<PurchasesButton />
			<AdvicesButton />
		</>
	);
};
