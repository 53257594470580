import React from 'react';
import styles from '../styles.module.scss';
import { GamePageButton } from '../../UI/GamePageButton';
import purchases from '../../../images/gamePageButtons/purchases.png';
import { showPurchasesModalAction } from '../../../store/actions/modal';
import { useAppDispatch } from '../../../hooks/redux';
import { PurchasesModal } from '../../PurchasesModal';

export const PurchasesButton: React.FC = () => {
	const dispatch = useAppDispatch();

	return (
		<div className={styles.purchases}>
			<GamePageButton
				onClick={() => {
					dispatch(showPurchasesModalAction());
				}}
				image={purchases}
			>
				<PurchasesModal />
			</GamePageButton>
		</div>
	);
};
