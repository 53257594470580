import React from 'react';
import styles from '../styles.module.scss';

export const AttackTab: React.FC = (): JSX.Element => {
	return (
		<>
			<div className={styles.tabContentHeader}>
				<span>Opponents</span>
				<span>Results</span>
			</div>
			<div className={styles.tabContentMain}>
				Beginning in the Bronze Age, a summary of your attack and defenses will
				be displayed here
			</div>
		</>
	);
};
